<template>
  <div class="news-item">
    <Article></Article>
  </div>
</template>

<script>

import Article from "../../layouts/Article";
export default {
  name: "NewsItem",
  components:{
    Article
  }
};
</script>

<style scoped>

</style>